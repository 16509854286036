import { Button, Dialog, Grid, Toast } from 'antd-mobile'
import React, { useEffect, useRef, useState } from 'react'
import './VipService.css'
import vipImg from '@/assets/vip.jpg'
import promVipImg from '@/assets/promotionvip.jpg'
import { getPayUrlApi, getProductsApi, getWaitOrdersApi, cancelOrderApi, getOrderStatusApi } from '@/services/order'
import { isMobile } from '@/utils/common'

export default function VipService() {
  const [products, setProducts] = useState([])
  const [waitOrders, setWaitOrders] = useState([])
  const orderRef = useRef(null)

  useEffect(() => {
    async function fetchData() {
      const { code, data, msg } = await getProductsApi()
      if (code !== 200) {
        Toast.show({ icon: 'fail', content: msg })
        return
      }
      setProducts(data.products)

      // 有促销信息，展示，促销信息内容 和倒计时
      if (data.promotion) {
        Dialog.alert({
          title: <span style={{ color: 'red' }}>限时特惠</span>,
          content: (
            <div style={{ textAlign: 'center' }}>
              <div dangerouslySetInnerHTML={{ __html: data.promotion.title }} />
              <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-around' }}>
                <span>活动时间：</span>
                <span>
                  <div>{data.promotion.start_time} 起</div>
                  <div>{data.promotion.end_time} 止</div>
                </span>
              </div>
            </div>
          ),
          closeOnMaskClick: true,
        })
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      const { code, data, msg } = await getWaitOrdersApi()
      if (code !== 200) {
        Toast.show({ icon: 'fail', content: msg })
        return
      }

      if (data.length > 0) {
        setWaitOrders(data)
        orderRef.current = data[0]
      }
    }
    fetchData()
  }, [])

  function handleShowVipInfo() {
    Dialog.show({
      title: 'VIP的权益',
      content: (
        <div>
          <p>1. 与虚拟人物的聊天每日1000次对话</p>
          <p>2. 所有功能均不受魅力值限制，且不消耗魅力值</p>
          <p>3. [随机匹配、捞个在线、捞瓶子]无限次，[扔瓶子]每日20次</p>
          <p>4. 可修改陌生人设置用于[随机匹配、捞个在线、捞瓶子]</p>
          <p>5. 捞个在线间隔10秒 (普通用户需间隔30秒)</p>
          {/* <p>5. 建立的群聊 所有成员均可发送文件</p> */}
          <p>6. 聊天双方 每日可发文件上限1000 (普通用户仅10)</p>
          <p>7. 聊天内文件保留3天 (普通用户只保留半小时) </p>
          <p>8. 聊天内的图片不进行缩放压缩，高清原图</p>
          <p>9. 所有功能 优先极速 匹配，独享匹配通道</p>
          <p>10. 可查看当前在线人数</p>
        </div>
      ),
      closeOnMaskClick: true,
    })
  }

  function handleBuy(title) {
    const isStopVip = false
    return async () => {
      if (isStopVip) {
        Toast.show({ icon: 'fail', content: '自动购买VIP暂时停止，如需购买请联系客服', duration: 5000 })
        return
      }
      if (!isMobile()) {
        Toast.show({ icon: 'fail', content: '仅支持手机端，请使用手机访问该页面购买', duration: 2000 })
        return
      }
      if (waitOrders.length > 0 || orderRef.current) {
        Toast.show({ icon: 'fail', content: '有待支付的订单，请先取消订单后购买', duration: 2000 })
        return
      }
      const { code, data, msg } = await getPayUrlApi(title)
      if (code !== 200) {
        Toast.show({ icon: 'fail', content: msg })
        return
      }
      setWaitOrders([data])
      orderRef.current = data
      setTimeout(() => {
        window.open(data.pay_url)
      }, 30)

      const result = await Dialog.confirm({
        title: '状态确认',
        cancelText: '未支付',
        confirmText: '我已支付',
        content: '是否已支付完成？',
      })

      if (result) {
        handleHasPayOrder(data.id)()
      } else {
        handleCancelOrder(data.id)()
      }
    }
  }

  function handleCancelOrder(orderId) {
    return async () => {
      const { code, data, msg } = await cancelOrderApi(orderId)
      if (code !== 200) {
        Toast.show({ icon: 'fail', content: msg })
        return
      }
      if (data === 'finish') {
        Dialog.alert({
          title: '提示',
          content: '该订单已支付成功，刷新页面即可开启vip',
        })
        setWaitOrders([])
        orderRef.current = null
      } else if (data === 'cancel') {
        Toast.show({ content: '订单已取消', duration: 2000 })
        setWaitOrders([])
        orderRef.current = null
      }
    }
  }

  function handleHasPayOrder(orderId) {
    return async () => {
      const { code, data, msg } = await getOrderStatusApi(orderId)
      if (code !== 200) {
        Toast.show({ icon: 'fail', content: msg })
        return
      }
      if (data === 'finish') {
        Dialog.alert({
          title: '提示',
          content: '该订单已支付成功，刷新页面即可开启vip',
        })
        setWaitOrders([])
        orderRef.current = null
      } else {
        Toast.show({ content: '该订单尚未支付完成', duration: 2000 })
      }
    }
  }
  if (!products.length) {
    return <div style={{ textAlign: 'center', fontSize: 16, marginTop: 100 }}>404</div>
  } else
    return (
      <div style={{ padding: '5px 30px', textAlign: 'center' }}>
        <div style={{ color: 'var(--adm-color-weak)' }}>
          <div>付款成功后，点击我已支付，将自动开通</div>
          <div>目前仅支持支付宝购买，可在设置页查看到期时间。</div>
          <div>
            <span style={{ fontWeight: 'bold' }}>网站不易，感谢支持！！</span>
          </div>

          <Button style={{ margin: '14px 0px' }} color="danger" size="small" fill="outline" onClick={handleShowVipInfo}>
            查看VIP权益
          </Button>
        </div>
        {waitOrders.length > 0 && (
          <div style={{ marginBottom: '14px' }}>
            {waitOrders.map((item, index) => {
              return (
                <div key={index}>
                  <span className="order-item">待支付</span>
                  <span className="order-item">{item.create_time.slice(0, 19)}</span>
                  <span className="order-item">{item.pay_fee_cent * 0.01} 元</span>
                  <Button
                    style={{ margin: '0 4px', padding: '0' }}
                    color="primary"
                    size="mini"
                    fill="none"
                    onClick={handleCancelOrder(item.id)}>
                    取消
                  </Button>

                  <Button
                    style={{ margin: '0 4px', padding: '0' }}
                    color="primary"
                    size="mini"
                    fill="none"
                    onClick={handleHasPayOrder(item.id)}>
                    我已支付
                  </Button>
                </div>
              )
            })}
          </div>
        )}

        <div style={{ marginBottom: 20 }}>
          <Grid columns={2} gap={10} style={{ marginTop: '0px' }}>
            {products.map((item, index) => {
              return (
                <Grid.Item key={index} className="card" onClick={handleBuy(item.title)}>
                  {item.is_promotion ? (
                    <img className="img" src={promVipImg} alt="" />
                  ) : (
                    <img className="img" src={vipImg} alt="" />
                  )}

                  <div className="time" style={{ color: item.is_hot ? 'red' : null }}>
                    {item.title}
                  </div>

                  <div className="pay-wrapper">
                    {/* <span className="item description">{item.desc}</span> */}
                    <span className="item">
                      <span className=" pay">{item.price}</span>元
                    </span>
                    <span className="item origin-pay">{item.old_price}元</span>
                  </div>
                </Grid.Item>
              )
            })}
          </Grid>
        </div>
        {/* <div style={{ color: 'var(--adm-color-danger)', marginTop: 14 }}>若需更长的时间，请联系客服</div> */}
      </div>
    )
}
