import { Button, Dialog } from 'antd-mobile'
import React from 'react'
import BannedUsers from './BannedUsers'

export default function TermOfService(props) {
  const { hideTitle } = props

  return (
    <div style={{ padding: '0 20px 0px 20px' }}>
      {!hideTitle && (
        <div>
          <h3>
            服务条款
            {/* <Button
              style={{ padding: 0, float: 'right' }}
              color="primary"
              fill="none"
              size="small"
              onClick={() => {
                Dialog.show({
                  title: '违规处理公示',
                  content: <BannedUsers />,
                  closeOnMaskClick: true,
                })
              }}>
              违规处理公示
            </Button> */}
          </h3>
        </div>
      )}
      <div>
        <p>
          本网站用于情绪宣泄的树洞。请大家绿色聊天，共建和谐社会。当你开始使用本网站聊天等各项服务时，意味着你已承诺遵守以下服务条款：
        </p>
        <p>1.本网站深知隐私信息对您的重要性，并会尽全力保护您的隐私信息安全可靠</p>
        <p>2.本网站仅服务年龄在18岁及以上心智成熟的成年人，否则请自行离开</p>
        <p>
          3.在本网站聊天服务中发布信息，用户需对自己的言行负责。本网站不允许发布信息中涉及政治、恐怖、暴力、色情、辱骂等任何违反法律、道德，侵害他人权益的内容
        </p>
        <p>4.在本网站聊天服务中发布信息，系统会使用机器识别的方式进行审查，必要时会进行人工介入审查</p>
        <p>
          5.本网站清理数据策略：1.上传的文件保留半小时后清理；2.删除的聊天记录保留5天后删除；3.未收藏的聊天
          过去3天未发消息后 删除；4.聊天记录保留30天后删除；5.收藏的聊天6个月无新消息删除
        </p>
        <p>
          6.本网站因资源及能力有限，在尽最大努力保护用户数据安全及服务稳定的同时，我们不能100%保证用户的数据完整及服务可靠性
        </p>
        <p>
          7.当用户受到举报、信息审查时发现违规行为、或用户言行不符合本网站服务原则时，本网站可以随时做出（包含但不限于）锁定用户、清除用户数据等操作
        </p>
        <p>
          8.本网站提供了断开会话、屏蔽用户、举报用户等操作，如果用户在会话中接收到任何违反本条款或违法的信息而没有主动断开，本网站平台则认为该用户主观上认同该行为并视会话双方均为违规；同时，用户须自行承担由此带来的一切后果
        </p>
        <p>9.用户在本网站平台发布的任何信息均为个人行为，不代表本网站服务平台的观点或者意见</p>
        <p>10.设备帐号使用Cookie进行存储，可能会由于Cookie清除等操作导致数据丢失，你需要及时绑定本网站帐号</p>
        <p>
          11.本网站将尽可能的封禁诈等违法犯罪行为，同时也将风险明确告知给了用户，若发生上当受骗等权益受到侵害的问题与本网站无关
        </p>
        <p>12.本网站遵守所在国家或地区的法律法规，会随时配合法律法规做出服务调整</p>
        <p>13.本服务条款会不定期更新，但所产生的变动不会主动通知用户，你可以随时访问本页面了解详情</p>
        <p>14.本网站网站拥有对服务条款的最终解释权</p>
      </div>
    </div>
  )
}
