import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Dialog, Collapse, Divider, Button, List, NoticeBar, Switch, Toast } from 'antd-mobile'
import { updateLogsData } from '@/const/updateLogsData'
import UserAccount from '@/components/user-settings/UserAccount'
import ActiveAccount from '@/components/user-settings/ActiveAccount'
import UserAvatar from '@/components/user-settings/UserAvatar'
import UserNicname from '@/components/user-settings/UserNicname'
import UserGender from '@/components/user-settings/UserGender'
import UserAge from '@/components/user-settings/UserAge'
import UserFakeLike from '@/components/user-settings/UserFakeLike'
import UserLocation from '@/components/user-settings/UserLocation'
import Feedback from '@/components/user-settings/Feedback'
import UserCallme from '@/components/user-settings/UserCallme'
import HelpCenter from '@/components/user-settings/HelpCenter'
import cookie from 'react-cookies'
import { updateUser, setUser } from '@/redux/actions/user'
import { getCurrUserApi, updateUserApi } from '@/services/user'
import { useNavigate } from 'react-router-dom'

import './UserSettings.css'
import { setDarkTheme, setLightTheme } from '@/utils/theme'
import DestroyAccount from '@/components/user-settings/DestroyAccount'
import UserTags from '@/components/user-settings/UserTags'

const UserSettings = (props) => {
  const { user, setUser, updateUser, openFeedback, broadcast } = props
  const [isLoading, setIsLoading] = React.useState(false)
  const [isDark, setIsDark] = React.useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    setIsDark(Number(cookie.load('isDark') || '0'))
  }, [])

  useEffect(() => {
    async function getUser() {
      const { data, code } = await getCurrUserApi(cookie.load('is_debug'))
      if (code === 200) {
        setUser(data)
      }
    }
    getUser()
  }, [])

  function handleChangeIsDark(value) {
    if (value) {
      setIsDark(1)
      setDarkTheme()
      cookie.save('isDark', 1)
    } else {
      setIsDark(0)
      setLightTheme()
      cookie.save('isDark', 0)
    }
  }

  const handleChangeAutoLogout = async (value) => {
    if (!user.account) {
      Toast.show({ icon: 'fail', content: '当前未登陆账号，不支持该设置' })
      return
    } else {
      handleChange({ auto_logout: value })
    }
  }

  const handleChangeAllowPicked = async (value) => {
    handleChange({ allow_picked: value })
  }

  const handleChangeShowLocation = async (value) => {
    handleChange({ show_location: value })
  }

  const handleChangeNotifySound = async (value) => {
    handleChange({ notify_sound: value })
  }

  const handleChangeIsGreen = async (value) => {
    handleChange({ is_green: value })
  }

  const handleChangeIsEnglish = async (value) => {
    handleChange({ is_english: value })
  }

  const handleChange = async (updateData) => {
    if (updateData.age && updateData.age < 18) {
      updateData.is_green = true
    }
    setIsLoading(true)
    const { code, msg } = await updateUserApi(updateData)
    setIsLoading(false)
    if (code === 200) {
      updateUser(updateData)
      if (updateData.age && updateData.age < 18) {
        Toast.show({
          icon: 'success',
          content: '更新成功，并自动为你开启绿色模式',
        })
      } else {
        Toast.show({
          icon: 'success',
          content: '更新成功',
        })
      }
    } else {
      Toast.show({
        icon: 'fail',
        content: msg,
      })
    }
    return { code, msg }
  }

  function handleShowTips() {
    Dialog.show({
      title: '魅力值说明',
      maskStyle: { opacity: 0.5 },
      content: (
        <div>
          <p>为维护良好的聊天环境，鼓励文明和谐友善的行为</p>
          <p style={{ color: 'var(--adm-color-primary)' }}>魅力值作用：</p>
          <p>1. 小于20，强制绿色模式，每日随机匹配最多200次，捞瓶子和捞在线最多2次</p>
          <p>2. 大于35，可发文件，可修改昵称，初始值是30</p>
          <p>3. 大于50，可上传自定义头像，可扔瓶子, 可捞瓶子</p>
          <p>4. 大于100，可发布寻人公告，女性用户“捞个在线”会优先捞大于100的用户</p>
          <p>5. 大于200，可修改陌生人设置</p>
          <p>6. 大于200后，每50魅力值增加1次所有功能每天的使用次数</p>
          <p>7. 魅力值越高，所有功能的优先级越高</p>
          <p>8. 魅力值越高，每日与虚拟人物对话的次数越多</p>

          <p style={{ color: 'var(--adm-color-primary)' }}>魅力值获取：</p>
          <p>1. 积极文明和谐的发言，系统实时给予相应的魅力值</p>
          <p>2. 系统智能判断行为良好的，给予一定的魅力值</p>
          <p>3. 得到对方点赞将获得对应的魅力值</p>
          <p>4. 每日进入平台自动获取5魅力值</p>
          <p>5. 购买会员，一次获得30魅力值</p>

          <p style={{ color: 'var(--adm-color-danger)' }}>魅力值扣除：</p>
          <p>1. 发送违规内容引起别人反感，根据违规内容扣相应的魅力值</p>
          <p>2. 聊天被举报或拉黑，经人工核实有违规行为，一次最少扣5魅力值</p>
          <p>3. 过多无意义发言，刷魅力值等行为，清空魅力值</p>
          <p>4. 上传头像人工审核不通过，一次扣30</p>
          <p>5. 修改昵称人工审核不通过，一次扣20</p>
          <p>6. 扔瓶子人工审核不通过，一次扣5</p>
          <p>7. 上传头像自动审核不通过，一次扣5</p>
        </div>
      ),
      closeOnMaskClick: true,
    })
  }

  function getLogItem(e, i) {
    return (
      <div
        className="update-logs-item"
        key={e.title}
        style={{ textAlign: i === 0 ? 'center' : 'left', color: i === 0 ? 'red' : null }}>
        <Divider style={{ margin: '6px 0px' }}>{e.title}</Divider>
        {e.contents.map((m, j) => {
          return (
            <div key={j}>
              <span>{m}</span>
            </div>
          )
        })}
      </div>
    )
  }

  function handleShowUpdateLogs() {
    Dialog.show({
      title: '更新日志',
      maskStyle: { opacity: 0.5 },
      content: (
        <div>
          {updateLogsData.slice(0, 3).map((e, i) => {
            return getLogItem(e, i)
          })}

          {updateLogsData.length > 3 && (
            <Collapse className="update-logs-more">
              <Collapse.Panel key="1" title={<div style={{ textAlign: 'center', padding: '0px' }}>查看更多</div>}>
                {updateLogsData.slice(3, updateLogsData.length).map((e, i) => {
                  return getLogItem(e, i + 1)
                })}
              </Collapse.Panel>
            </Collapse>
          )}
        </div>
      ),
      closeOnMaskClick: true,
    })
  }

  return (
    <>
      {broadcast && broadcast['settings'] && <NoticeBar content={broadcast['settings']} color="alert" />}
      <List>
        <UserAccount user={user} account={user.account} />
        <ActiveAccount user={user} bind={user.bind} />

        <UserAvatar isLoading={isLoading} avatar={user.avatar} onChange={handleChange} />
        <UserNicname nickname={user.nickname} onChange={handleChange} />
        <UserGender gender={user.gender} onChange={handleChange} />
        <UserAge age={user.age} onChange={handleChange} />

        {/* <UserLocation location={user.location} onChange={handleChange} /> */}
        <List.Item
          style={{ textDecoration: 'none', lineHeight: '40px' }}
          extra={<Switch checked={user.show_location} onChange={handleChangeShowLocation} />}>
          <span style={{ color: 'var(--theme-text-color)' }}>显示位置（{user.location}）</span>
        </List.Item>

        <UserTags tags={user.tags} onChange={handleChange}></UserTags>

        <List.Item
          style={{ textDecoration: 'none', lineHeight: '40px' }}
          extra={
            <div>
              <Button color="primary" fill="none" size="mini" onClick={handleShowTips}>
                魅力值说明
              </Button>
              <span>{user.likes || 0}</span>
            </div>
          }>
          <span style={{ color: 'var(--theme-text-color)' }}>魅力值</span>
        </List.Item>

        {user.user_type !== 'try' && (
          <List.Item
            style={{ textDecoration: 'none', lineHeight: '40px' }}
            onClick={() => {
              navigate(`/vip`)
            }}
            extra={<span></span>}>
            <span style={{ color: '#ee5353' }}>VIP服务（开通VIP、VIP权益说明）</span>
          </List.Item>
        )}

        <UserCallme user={user} />

        <List.Item
          style={{ textDecoration: 'none', lineHeight: '40px' }}
          extra={<Switch checked={user.allow_picked} onChange={handleChangeAllowPicked} />}>
          <span style={{ color: 'var(--theme-text-color)' }}>允许被“捞个在线”发现</span>
        </List.Item>

        <List.Item
          style={{ textDecoration: 'none', lineHeight: '40px' }}
          extra={
            <Switch style={{ '--checked-color': '#00b578' }} checked={user.is_green} onChange={handleChangeIsGreen} />
          }>
          <span style={{ color: '#06a972' }}>绿色模式（私聊屏蔽所有敏感词）</span>
        </List.Item>

        <List.Item
          style={{ textDecoration: 'none', lineHeight: '40px' }}
          extra={<Switch checked={isDark} onChange={handleChangeIsDark} />}>
          <span style={{ color: 'var(--theme-text-color)' }}>夜间模式（深色主题，呵护您的双眼）</span>
        </List.Item>

        <List.Item
          style={{ textDecoration: 'none', lineHeight: '40px' }}
          extra={<Switch checked={user.notify_sound} onChange={handleChangeNotifySound} />}>
          <span style={{ color: 'var(--theme-text-color)' }}>通知声音</span>
        </List.Item>

        <List.Item
          style={{ textDecoration: 'none', lineHeight: '40px' }}
          extra={<Switch checked={user.auto_logout} onChange={handleChangeAutoLogout} />}>
          <span style={{ color: 'var(--theme-text-color)' }}>关闭浏览器自动退出账号</span>
        </List.Item>

        <List.Item
          style={{ textDecoration: 'none', lineHeight: '40px' }}
          extra={<Switch checked={user.is_english} onChange={handleChangeIsEnglish} />}>
          <span style={{ color: 'var(--theme-text-color)' }}>英语模式（私聊只能用字母交流）</span>
        </List.Item>

        <UserFakeLike fake_likes={user.fake_likes} onChange={handleChange} />
        {user.likes > 50 && <Feedback openFeedback={openFeedback} />}

        {/* <HelpCenter /> */}

        <List.Item
          className="helpitem"
          style={{ textDecoration: 'none', lineHeight: '40px' }}
          extra={
            <div style={{ margin: '10px 0px', textAlign: 'center' }}>
              <Button color="primary" fill="none" size="mini" onClick={handleShowUpdateLogs}>
                更新日志
              </Button>

              <Button
                color="primary"
                fill="none"
                size="mini"
                onClick={() => {
                  window.open('/tos')
                }}>
                服务条款
              </Button>
            </div>
          }>
          <span style={{ color: 'var(--theme-text-color)' }}>帮助信息</span>
        </List.Item>

        <DestroyAccount />
      </List>
    </>
  )
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

function mapDispatchProps(dispatch) {
  return {
    setUser: (user) => dispatch(setUser(user)),
    updateUser: (updateData) => dispatch(updateUser(updateData)),
  }
}

export default connect(mapStateToProps, mapDispatchProps)(UserSettings)
